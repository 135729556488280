const awsmobile = {

  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:f36a47a6-2fd3-427a-839e-f3de1b488afc",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_bHE53uHdJ",
  aws_user_pools_web_client_id: "6btjvv2tn7se9pvko9n5ndql0c",
  aws_access_key_id: "AKIAWFIPTIBW7STUEQFH",
  aws_secret_access_key: "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",
  s3_bucket: "vcipliveness",
  
};
export default awsmobile;
